import React from "react";
import { Routes, Route } from "react-router-dom";
import AuthGuard from "../guards/AuthGuard";
import App from "../components/App";
import DocView from "../components/DocView";

console.log("in AuthRoutes!!!!");
const AuthRoutes = [
        <Route key="Editor" path="/" element={<AuthGuard children={<App />} />} />,
        <Route key="DocView" path="/docview" element={<AuthGuard children={<DocView />} />} />
    ];

export default AuthRoutes;