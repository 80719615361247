import React from "react";
import { Route } from "react-router-dom";
// import UnAuthGuard from "../guards/UnAuthGuard";
import Login from "../components/Login";
// import Register from "../pages/Register";

console.log('in UnAuthRoutes!!!!');
const UnAuthRoutes = (
    <Route key="Login" path="/login" element={<Login />} />
)

export default UnAuthRoutes;