import * as React from 'react';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import Box from '@mui/joy/Box';
import Radio from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';

export default function CustomRadioGroup({ varColorsMap, varConceptMap, onChange }) {
  const [selected, setSelected] = React.useState(null);
  const [variant, setVariant] = React.useState('outlined');
  
  const handleClick = (label) => {
    setSelected(selected === label ? null : label);
    onChange(label);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 1, // Fixed gap between buttons
      }}
    >
      {Object.keys(varColorsMap).sort().map((label) => (
        <Tooltip
          key={label}
          title={varConceptMap[label]}
          color="neutral" // Tooltip color remains neutral
          placement="top"
          variant={variant}
        >
          <Button
            variant={selected === label ? 'solid' : 'solid'}
            onClick={() => handleClick(label)}
            sx={{
              padding: '6px 12px', // Fixed padding for flexible width
              border: '2px solid darkgray',
              borderRadius: '8px',
              fontSize: '1rem',
              bgcolor: selected === label ? varColorsMap[label] : 'transparent',
              color: selected === label ? 'black' : 'darkgray',
              '&:hover': {
                bgcolor: selected === label ? varColorsMap[label] : `${varColorsMap[label]}90`, // 50% opacity on hover
              },
            }}
          >
            {label}
          </Button>
        </Tooltip>
      ))}
    </Box>
  );
}
