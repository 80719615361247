import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
// import App from './components/App';
import { store, persister } from './store';
import { BrowserRouter, Routes } from 'react-router-dom';
import AuthRoutes from './routes/AuthRoutes';
import UnAuthRoutes from './routes/UnAuthRoutes';

ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persister}>
        <BrowserRouter>
          <Routes>
            {AuthRoutes}
            {UnAuthRoutes}
          </Routes>
        </BrowserRouter>
      </PersistGate>
    </Provider>,
  document.getElementById('root')
);
