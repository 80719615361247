import React, { useState } from 'react';
import PopupWindow from './AMRGuidePage';
import { Button, Typography, Grid, Card, CardContent } from '@mui/material';
import NewWindow from 'react-new-window';

const AMRGuidePop = (unloadFunc) => {
  const [openWindows, setOpenWindows] = useState([]);

  const openPopup = (fileUrl) => {
    setOpenWindows([...openWindows, fileUrl]);
  };

  const handleUnload = (fileUrl) => {
    setOpenWindows(openWindows.filter(url => url !== fileUrl));
  };

  return (
  <NewWindow
        title="Propbank Lookup"
        onUnload={unloadFunc}
        fetures={{left: 200, top: 200, width: 400, height: 200}}      
      >
      <Card sx={{ width: '100%' }}>
      
      <CardContent>
    
      <Typography variant="h6" gutterBottom>AMR Guides Documents</Typography>
      <Grid container spacing={2}>
        <Grid item>
          <Button variant="outlined" color="success" style={{textTransform: 'none'}} onClick={() => openPopup('/amr/lib/amr-dict.html')}>
            AMR Dictionary
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" color="success" style={{textTransform: 'none'}} onClick={() => openPopup('/amr/lib/amr-dict-biomed.html')}>
            AMR Dictionary (Bio Medical)
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" color="success" style={{textTransform: 'none'}} onClick={() => openPopup('/amr/lib/amr-dict.html')}>
            About Checker 
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" color="success" style={{textTransform: 'none'}} onClick={() => openPopup('/amr/lib/about-checker.html')}>
            About Checker 
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" color="success" style={{textTransform: 'none'}} onClick={() => openPopup('/amr/lib/guidelines-checker-example.html')}>
            Guidelines
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" color="success" style={{textTransform: 'none'}} onClick={() => openPopup('/amr/lib/ne-types.html')}>
            NE types
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" color="success" style={{textTransform: 'none'}} onClick={() => openPopup('/amr/lib/roles.html')}>
            Roles
          </Button>
        </Grid>
      </Grid>
      </CardContent>
      {openWindows.map((url, index) => (
        <PopupWindow key={index} url={url} onUnload={() => handleUnload(url)} />
      ))}
    </Card>
  </NewWindow>
  );
};

export default AMRGuidePop;