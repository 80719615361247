
import React, { useState, useEffect } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


// import Stack from '@mui/material/Stack';

import Button from '@mui/joy/Button';


// ----------------table------------------

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import LockResetIcon from '@mui/icons-material/LockReset';
import CloseIcon from '@mui/icons-material/Close';

// --------------end table----------------
import { getProfileData, postProfileData, postPassWChange } from '../services/auth';
import { LOGOUT } from '../store/actions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';


function ProfileManager({show, onHide}) {

  const dispatch = useDispatch();
  // const [checked, setChecked] = useState(true);
  const navigate = useNavigate();  
  const [firstName, setFirstName] = useState('First Name');
  const [lastName, setLastName] = useState('Last Name');
  const [email, setEmail] = useState('email@domain.com');
  const [origFName, setOrigFName] = useState('');
  const [origLName, setOrigLName] = useState('');
  const [needSave, setNeedSave] = useState(false);
  const [OpenProfileSnack, setOpenProfileSnack] = useState(false);
  const [profileSnackMsg, setProfileSnackMsg] = useState('');

  // password change states
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [newPasswordValid, setNewPasswordValid] = useState(false);
  const [openPasswordSnack, setOpenPasswordSnack] = useState(false);
  const [passwordSnackMsg, setPasswordSnackMsg] = useState('');
  const [passwordSnackColor, setPasswordSnackColor] = useState('warning');


  useEffect(() => {
     (async () => {
        const fetchedProfile = await getProfileData().catch((e) => {
          console.log(e);
          dispatch({ type: LOGOUT });
          navigate('/login', { replace: true });
          });
        if (fetchedProfile === undefined || fetchedProfile.length < 1) {
          console.log('not data fetched');

          } else {
          console.log("fetchedProfile: ", fetchedProfile);
          setFirstName(fetchedProfile['first_name']);
          setLastName(fetchedProfile['last_name']);
          setEmail(fetchedProfile['email']);
          setOrigFName(fetchedProfile['first_name']);
          setOrigLName(fetchedProfile['last_name']);
          }
      })();
    // eslint-disable-next-line
    }, []
  );


  // start onchange states ------------------------------
  const FNameOnChange = (e) => {
    if (e.target.value !== origFName) {
      setNeedSave(true);
      setFirstName(e.target.value);
    } else {
      setNeedSave(false);
    }
  }
  const LNameOnChange = (e) => {
    if (e.target.value !== origLName) {
      setNeedSave(true);
      setLastName(e.target.value);
    } else {
      setNeedSave(false);
    }
  }

  // end onchange states ------------------------------
  // start name change submission ---------------------
  const submitProfileUpdate = async (e) => {
    const newProfileDict = {'first_name': firstName,
                            'last_name': lastName,
    };
    try {
      const resp = await postProfileData(newProfileDict);
      if (resp !== undefined) {
        setProfileSnackMsg('Profile updated!!');
        setOpenProfileSnack(true);
        setOrigFName(firstName);
        setOrigLName(lastName);
        setNeedSave(false);
      }

    } catch (error) {
      console.log('in submitProfileUpdate error: ', error);
      setProfileSnackMsg('Profile update failed...');
      setOpenProfileSnack(true);
    }
  }
  // end name change submission ---------------------
  // start change password funcs --------------------
  // Check if both passwords match. Call this function whenever the user modifies the repeated password.
  const checkPasswordsMatch = () => {
    if (newPassword !== repeatPassword) {
      return true;
    } else {
      return false;
    }
  };  

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[a-zA-Z]).{8,}$/;
    return passwordRegex.test(password);
  };

  const newPasswordOnChange = (e) => {
    if (validatePassword(e.target.value)){
      setNewPassword(e.target.value);
      setNewPasswordValid(true);
    }
  };

  const handlePasswordCSubmit = async (e) => {
    if (checkPasswordsMatch()) {
      setPasswordMismatch(true);
    } else {
      setPasswordMismatch(false);
      setPasswordSnackMsg('New Password Submitted, updating...');
      setPasswordSnackColor('primary');
      setOpenPasswordSnack(true);
      const passwordsDict = { 'old_password': oldPassword,
                          'new_password': newPassword, };
      try {
        const resp = await postPassWChange(passwordsDict);
        if (resp instanceof Error) {
          setPasswordSnackMsg('Password update failed...');
          setPasswordSnackColor('warning');
          setOpenPasswordSnack(true);
          setTimeout(() => {
            dispatch({ type: LOGOUT });
            navigate('/login', { replace: true });
          }, 4500);
          
        } else if(typeof resp === 'object') {
          setPasswordSnackMsg('Password Updated!');
          setPasswordSnackColor('success');
          setOpenPasswordSnack(true);
          setTimeout(() => {
            setShowPassChange(false);
          }, 4500);

        }
      } catch (error) {
        console.log(error);
        setPasswordSnackMsg('Password update failed...');
        setOpenPasswordSnack(true);
        setTimeout(() => {
          dispatch({ type: LOGOUT });
          navigate('/login', { replace: true });
        }, 4500);
      }     
    }
  };
  // end change password func -----------------------

  const [showPassChange, setShowPassChange] = useState(false);
  const hidePassWChangeDialog = () => setShowPassChange(false);

  return (
    <Dialog 
      open={show}
      onClose={onHide}
      maxWidth='lg'
      fullWidth={true}
      style={{zIndex: 11}}
    >    
      <DialogTitle style={{ textAlign: 'center', margin: 'auto' }}>User Profile</DialogTitle>
      <DialogContent style={{zIndex: 11}}>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{
            '& .MuiTextField-root': {m:1 , width: '100%' },
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                id="firstName"
                label="First Name"
                defaultValue={firstName}
                onChange={(e) => FNameOnChange(e)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="lastName"
                label="Last Name"
                defaultValue={lastName}
                onChange={(e) => LNameOnChange(e)}
              />     
            </Grid>
            <Grid item xs={12}>  
              <TextField
                disabled
                id="outlined-disabled"
                label="Email"
                defaultValue={email}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          component="label"
          role={undefined}
          tabIndex={-1}
          variant="outlined"
          color="success"
          startDecorator={<LockResetIcon />}
          onClick={() => setShowPassChange(true)}
        >
          Change Password
        </Button>
        {needSave
          ? <Button variant="outlined" onClick={submitProfileUpdate} color="success">Save</Button>
          : <Button variant="outlined" onClick={onHide}>Close</Button>
        }
        <Snackbar
          open={OpenProfileSnack}
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
          autoHideDuration={4000}
          onClose={(e) => setOpenProfileSnack(false)}
          message={profileSnackMsg}
          action={
            <React.Fragment>
              <IconButton
                aria-label="close"
                color="inherit"
                sx={{ p: 0.5 }}
                onClick={(e) => setOpenProfileSnack(false)}
              >
                <CloseIcon />
              </IconButton>
            </React.Fragment>
          }
        />
      </DialogActions>
      <Dialog
        open={showPassChange}
        onClose={hidePassWChangeDialog}
        maxWidth='md'
        style={{zIndex: 12}}
      >
        <DialogTitle style={{ textAlign: 'center', margin: 'auto' }}>Change Password</DialogTitle>
        <DialogContent style={{zIndex: 12}}>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{
              '& .MuiTextField-root': {m:1 , width: '100%' },
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  id="oldPassword"
                  label="Current Password"
                  type="password"
                  variant="outlined"
                  onChange={(e) => setOldPassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="newPassword"
                  label="New Password"
                  type="password"
                  variant="outlined"
                  error={!newPasswordValid}
                  helperText={newPasswordValid ? "" : "1 special symbol, 1 number and at lesat 8 characters"}
                  onChange={(e) => newPasswordOnChange(e)}
                />     
              </Grid>
              <Grid item xs={4}>  
                <TextField
                  id="repeatNewPassword"
                  label="Repeat New Password"
                  type="password"
                  error={passwordMismatch}
                  helperText={passwordMismatch ? "Passwords do not match" : ""}
                  variant="outlined"
                  onChange={(e) => setRepeatPassword(e.target.value)}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          {/*<Button variant="warning" onClick={handleFileSelection}>Open</Button>*/}
          <Button variant="outlined" onClick={hidePassWChangeDialog} color="primary">Cancel</Button>
          <Button variant="outlined" onClick={(e) => handlePasswordCSubmit(e)} color="success">Submit</Button>
        </DialogActions>
        <Snackbar
          open={openPasswordSnack}
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
          autoHideDuration={4000}
          onClose={(e) => setOpenPasswordSnack(false)}
          variant="filled">
            <Alert
              onClose={(e) => setOpenPasswordSnack(false)}
              severity={passwordSnackColor}
              variant="filled"
              sx={{ width: '100%' }}
              >
              {passwordSnackMsg}
            </Alert>

        </Snackbar>
      </Dialog>
    </Dialog>
  );
}


export default ProfileManager;