import React from "react";

import styles from "./editors.module.css";

import CodeMirror from '@uiw/react-codemirror';

import { sublime } from '@uiw/codemirror-theme-sublime';

import { AMR } from "./penman-grammar-parser-highlight";
import { indentationMarkers } from "@replit/codemirror-indentation-markers";

import './custom-completionInfo-style.css';
import { checkNewVar } from '../utils/amrUtils';
// Define the extensions outside the component for the best performance.
// If you need dynamic extensions, use React.useMemo to minimize reference changes
// which cause costly re-renders.
// const code = "(m / make-01 :ARG0 (p / person ))"



const extensions = [AMR(), indentationMarkers()];
// -------------------------------------------------------- 

export const ExtraEditor = (props) => {
  return <Editor mode="javascript" title={"parser suggestion"} {...props} />;
};

export const TextEditor = (props) => {
  return <Editor mode="text" title={"Text"} {...props} />;
};

export const AMREditor = (props) => {
  return <Editor mode="amr" title={"AMR"} {...props} />;
};

const Editor = ({ mode, value, setValue, title, height, amrVars, setAMRVars, setAMRVarsMap }) => {

  const onChange = React.useCallback((newValue, viewUpdate) => {
    // console.log("newValue:", newValue);
    if( amrVars !== undefined ){
      const new_flag_tuple = checkNewVar(newValue, amrVars);
    
      if(!new_flag_tuple[0]){

        setAMRVars(new_flag_tuple[1]);
        setAMRVarsMap(new_flag_tuple[2]);
      }
    }
 
    setValue(newValue);
  }, []);
  return  (
    <div className={styles.editorContainer}>
      <div className={styles.editorTitle}>{title}</div>
      {/*<div ref={editor}/>*/}
      <CodeMirror
        className="custom-completionInfo"
        value={value}
        height="100%"
        theme={sublime}
        extensions={extensions}
        onChange={onChange}
      />
    </div>
  );
};
