import { LRParser } from '@lezer/lr';
import { LRLanguage, indentNodeProp, delimitedIndent, foldNodeProp, foldInside, LanguageSupport } from '@codemirror/language';
import {completeFromList, snippetCompletion} from "@codemirror/autocomplete"
import { styleTags, tags } from '@lezer/highlight';
//
import {
    // Completion,
    // CompletionContext,
    // CompletionResult,
    autocompletion,
    // closeCompletion,
    // startCompletion,  
} from "@codemirror/autocomplete";
// import { EditorView, ViewUpdate } from '@codemirror/view';
// import { EditorState, EditorView, basicSetup } from "@codemirror/basic-setup";
// import { debounce } from "lodash";
// import axios from 'axios';
import { getAutoCompleteSnippet } from '../services/auth';
import { basicSetup, minimalSetup } from '@uiw/codemirror-extensions-basic-setup';
import { keymap } from "@codemirror/view";
import { updateVars } from '../utils/amrUtils';
// This file was generated by lezer-generator. You probably shouldn't edit it.
const parser = LRParser.deserialize({
  version: 14,
  states: "!vOVQPOOO[QPO'#C_QOQPOOOaQQO,58yOfQPO1G.eOqQSO'#CcOOQO'#Cj'#CjOvQPO7+$PO!RQWO,58}OOQO-E6h-E6hOVQPO'#ChOOQO1G.i1G.iO!ZQPO,59SOOQO1G.n1G.n",
  stateData: "!`~OPOS~OSPO~OTRO~OUSO~OWTO_RiYRi~OXWO~OWTO_RqYRq~OZYO]ZO~OY]O~O",
  goto: "s_PPP`PPPfPPPPjPmQQOR[YTUSVRZWQVSRXV",
  nodeNames: "⚠ WhiteSpace Node Expression Identifier Slash NodeLabel Edge Colon EdgeLabel CloseParen OpenParen Subtree Constant",
  maxTerm: 16,
  nodeProps: [
    ["openedBy", 10,"OpenParen"],
    ["closedBy", 11,"CloseParen"]
  ],
  skippedNodes: [0,1],
  repeatNodeCount: 1,
  tokenData: "$`~R`X^!Tpq!Txy!xyz!}!P!Q#S![!]#X!c!}#^#T#o#^#y#z!T$f$g!T#BY#BZ!T$IS$I_!T$I|$JO!T$JT$JU!T$KV$KW!T&FU&FV!T~!YYP~X^!Tpq!T#y#z!T$f$g!T#BY#BZ!T$IS$I_!T$I|$JO!T$JT$JU!T$KV$KW!T&FU&FV!T~!}OZ~~#SOY~~#XOT~~#^OW~_#iT]WXSSPUQ}!O#x!Q![#x!c!}#^#R#S#x#T#o#^U$PTXSUQ}!O#x!Q![#x!c!}#x#R#S#x#T#o#x",
  tokenizers: [0, 1, 2, 3],
  topRules: {"Node":[0,2]},
  tokenPrec: 0
});

let AMRLanguage = LRLanguage.define({
  parser: parser.configure({
    props: [
      styleTags({
        Identifier: tags.variableName,
        NodeLabel: tags.atom,
        EdgeLabel: tags.propertyName,
        Constant: tags.string,
        OpenParen: tags.paren,
        CloseParen: tags.paren,
        Slash: tags.punctuation,
        Colon: tags.punctuation,
      }),
    foldNodeProp.add({
      Subtree: foldInside
    })
      // Add any other language properties here if necessary
    ]
  }),
  languageData: {
    commentTokens: { line: "//" }
    // Adjust this line if AMR uses a different style of comments
  }
});


const amr_relations = [':ARG0', ':ARG1', ':ARG2', ':ARG3', ':ARG4', ':ARG5', ':ARG6', ':location', ':accompanier', 'age', ':beneficiary', ':concession', ':condition', ':degree', ':destination', ':duration', ':extent', ':frequency', ':instrument', ':li', ':location', ':manner', ':mod', ':name', ':ord', ':part',':polarity', ':poss', ':purpose', ':quant', ':source', ':subevent', ':time', ':topic', ':value', ':op1', ':op2', ':op3', ':op4', ':op5', ':calendar', ':century', ':day', ':dayperiod', ':decade', ':era', ':month', ':quarter', ':season', ':timezone', ':weekday', ':year', ':unit', ':direction', ':scale'];

// ws explore code ===============
function infoDomCreater(completion){
    console.log("completion: ", completion);
    const lines = completion.infolines;
    const snippet_dom = document.createElement("div");
    snippet_dom.className = "container";
    for (var i=0; i <= lines.length - 1;i++) {
        const line_dom = document.createElement('div');
        const line_text = document.createTextNode(lines[i]);
        line_dom.appendChild(line_text);
        snippet_dom.appendChild(line_dom);
    }
    return snippet_dom;
};


async function autocompleteAPIcall(context, word) {

    const data = {context_obj: context, word_obj: word};
    console.log("autocompleteAPIcall, data: ", data);
    if(word.text.startsWith(":")){
        const filtered_rels = amr_relations.filter(str => str.startsWith(word.text));
        const autocomplete_results = filtered_rels.map((r) => ({label: r, type: 'keyword'}));
        console.log("autocomplete_results: ", autocomplete_results);
        return autocomplete_results;
    } else {
        try{
            const response = await getAutoCompleteSnippet(data);  
            console.log("response: ", response);
            // const snippet = snippetCompletion('(${var} / make-01 \n\t:ARG0 (${var1} / ${concept1}) \n\t:ARG1 (${var2} / ${concept2})', {label: 'make-01', info: 'make-01-snippet'});
            // const withsnippet = [...response.data, snippet];
            // return withsnippet;

            const propbanksnippets = response.map((snippet_dict) => {
                // const lines = snippet_dict.info.split('|');

                return snippetCompletion(snippet_dict.tmp_str, {label: snippet_dict.label, info: infoDomCreater, infolines: snippet_dict.info});
                // return snippetCompletion(snippet_dict.tmp_str, {label: snippet_dict.label, info: snippet_dict.info});
            });
            console.log("propbanksnippets:");
            console.log(propbanksnippets);
            console.log("-----------------");
            return propbanksnippets;
        } catch(error) {
            console.log("error: ", error);
            return [
                {label: "match", type: "keyword"},
                {label: "hello", type: "variable", info: "(World)"},
                {label: "magic", type: "text", apply: "⠁⭒*.✩.*⭒⠁", detail: "macro"}
            ];
        }        
    }

}

async function myCompletions(context) {
  let word = context.matchBefore(/[A-Za-z0-9:_-]*/);
  if (word.from === word.to && !context.explicit){
    return null;
  } else {
    const options_ls = await autocompleteAPIcall(context, word);
    
    return {
        from: word.from,
        options: options_ls
    }; 
  }
}

// ---------------var update extension--------------
function varUpdateFunction(view) {
  const currentContent = view.state.doc.toString();
  const updatedContent = updateVars(currentContent);
  // update var 
  view.dispatch({
    changes: { from: 0, to: currentContent.length, insert: updatedContent }
  });
  return true; // Prevents the default behavior
}

const extensions = [
  autocompletion({
    activateOnTyping: false,
    override: [myCompletions],
  }),
  keymap.of([{
    key: "Ctrl-u",
    run: varUpdateFunction,
  }]),
  // basicSetup({
  //   foldGutter: true,
  // }),
  // customCompletionDisplay(),
];
// ===============================

function AMR() {
    // return new LanguageSupport(AMRLanguage, [AMRCompletion]);
    return new LanguageSupport(AMRLanguage, extensions);
    // return new LanguageSupport(AMRLanguage, [ls]);
}

export { AMR, AMRLanguage };
