import React, { useState } from 'react';
import './text-annotator.css';

// Function to convert token indices to character offsets
const tokenIndexToCharOffset = (tokens, startIndex, endIndex) => {
    let startCharIdx = 0;
    let endCharIdx = 0;

    for (let i = 0; i < tokens.length; i++) {
        if (i < startIndex) {
            startCharIdx += tokens[i].length; 
        }

        if (i <= endIndex) {
            endCharIdx += tokens[i].length; 
        }
    }

    // Adjust the endCharIdx to remove the extra space after the last token
    // endCharIdx = endCharIdx - 1;

    return { start: startCharIdx, end: endCharIdx };
};

// Function to convert character offsets to token indices
const charOffsetToTokenIndex = (tokens, charStart, charEnd) => {
    let currentCharOffset = 0;
    let startIndex = -1;
    let endIndex = -1;
    

    for (let i = 0; i < tokens.length; i++) {
        const tokenLength = tokens[i].length;
        const tokenStart = currentCharOffset;
        const tokenEnd = tokenStart + tokenLength; // Adjust for zero-based indexing
        
        // Check if charStart falls within the current token
        if (startIndex === -1 && charStart >= tokenStart && charStart < tokenEnd) {
            startIndex = i;
        }

        // Check if charEnd falls within the current token
        if (charEnd > tokenStart && charEnd <= tokenEnd) {
            endIndex = i;
            break; // We can stop early if both indices are found
        }

        currentCharOffset += tokenLength; // +1 for space
    }
    
    // Handle edge case where charEnd might be beyond the last token
    if (endIndex === -1 && charEnd >= currentCharOffset - 1) {
        endIndex = tokens.length - 1;
    }

    return { start: startIndex, end: endIndex };
};


const CharOffsets2TokenIndices = (tokens, charSpans, colorbook) => {
    const updatedTokenSpans = charSpans.map((charSpan) => {
        const tokenStartEnd = charOffsetToTokenIndex(tokens, charSpan.start, charSpan.end);
        return {
            id: `span-${charSpan.tag}-${tokenStartEnd.start}-${tokenStartEnd.start}`, 
            start: tokenStartEnd.start,
            end: tokenStartEnd.end,
            label: charSpan.tag,
            color: colorbook[charSpan.tag],
            surface: charSpan.surface
        };
    });
    return updatedTokenSpans;
};

const TokenIndices2CharOffsets = (tokens, tokenSpans) => {
    const updatedCharSpans = tokenSpans.map(tokenSpan => {
        const charStartEnd = tokenIndexToCharOffset(tokens, tokenSpan.start, tokenSpan.end);
        return {
            id: `span-${tokenSpan.label}-${tokenSpan.start}-${tokenSpan.end}`,
            ...tokenSpan,
            start: charStartEnd.start,
            end: charStartEnd.end,
            var: tokenSpan.label,
            tag: tokenSpan.label,
            surface: tokenSpan.surface
        };
    });
    return updatedCharSpans;
};

const TextAnnotator = ({ tokens, alignments, setAlignments, selectedTag, tagColor, tagcolors }) => {
    // const [selectedSpans, setSelectedSpans] = useState([]);
    
    
    const selectedSpans = CharOffsets2TokenIndices(tokens, alignments, tagcolors);
    console.log('start TextAnnotator, alignments: ', alignments);
    
    const handleMouseUp = () => {
        const selection = window.getSelection();
        const range = selection.getRangeAt(0);
        const selectedText = range.toString().trim();
        console.log(`selection: ${selection} | range: ${range} | selectedText: ${selectedText}`);
        
        if (selectedText) {
            const startSpan = range.startContainer.parentNode;
            const endSpan = range.endContainer.parentNode;

            const startIndex = parseInt(startSpan.getAttribute('id'));
            const endIndex = parseInt(endSpan.getAttribute('id'));
            
            
            
            if (startIndex <= endIndex) {
                const spanId = `span-${startIndex}-${endIndex}`;  // Unique identifier for the span group

                const charoffsets = tokenIndexToCharOffset(tokens, startIndex, endIndex);
                
                
                const newSelectedSpan = {
                    id: spanId,
                    start: charoffsets.start,
                    end: charoffsets.end,
                    label: selectedTag,
                    color: tagColor,
                    tag: selectedTag,
                    var: selectedTag,
                    surface: selectedText
                };
                console.log('Handle Mouseup, newSelectedSpan: ', newSelectedSpan);
                setAlignments([...alignments, newSelectedSpan]);
            }
        }

        selection.removeAllRanges(); // Clear selection
    };

    const handleRemoveSpan = (spanId) => {
        
        
        // const spansToRemove = selectedSpans.filter(span => span.id === spanId);
        // setSelectedSpans(selectedSpans.filter(span => span.id !== spanId));
        
        const newSelectedSpans = selectedSpans.filter(span => span.id !== spanId);
        const newCharSpans = TokenIndices2CharOffsets(tokens, newSelectedSpans);
        console.log('before updating removed token span, alignments:', alignments);
    
        const newAlignments = newCharSpans.map(charspan => ({
            ...charspan,
            label: charspan.label,
            tag: charspan.label,
            var: charspan.var ?? charspan.label,
            surface: charspan.surface

        }));
        
        
        setAlignments(newAlignments);

        // Adjust the height of the affected spans
        // spansToRemove.forEach(removedSpan => {
        //     const affectedSpans = selectedSpans.filter(span => span.start <= removedSpan.end && span.end >= removedSpan.start);
        //     affectedSpans.forEach(span => {
        //         const index = selectedSpans.indexOf(span);
        //         selectedSpans[index].height -= 17; // Reduce height for each removal
        //     });
        // });
    };

    const calculateHeight = (originalHeight, insertions) => {
        return parseFloat(originalHeight) + 17 * insertions + 'px';
    };

    const renderToken = (token, index) => {
        const relevantSpans = selectedSpans.filter(span => span.start <= index && index <= span.end);
        const insertionCount = relevantSpans.length;

        let tokenContent = token;
        let spanClassName = 'token-block';
        let spanStyle = { fontWeight: insertionCount > 0 ? 'bold': 'normal', height: calculateHeight('60px', insertionCount) };

        if (relevantSpans.length > 0) {
            tokenContent = (
                <>
                    {token}
                    {relevantSpans.map((span, idx) => {
                        if (span.start === index) {
                            return (
                                <span key={idx} className="label-head label-line" style={{ background: span.color, top: `${40 + idx * 17}px`, height: '4px' }} onClick={() => handleRemoveSpan(span.id)}>
                                    <span className="span-label" style={{ background: span.color, color: 'rgb(0, 0, 0)' }}>{span.label}
                                    </span>
                                </span>
                            );
                        } else {
                            return (
                                <span key={idx} className="label-line" style={{ background: span.color, top: `${40 + idx * 17}px`, height: '4px' }} onClick={() => handleRemoveSpan(span.id)} >
                                </span>
                            );
                        }
                    })}
                </>
            );
        }

        return (
            <span
                className={spanClassName}
                id={index}
                style={spanStyle}
            >
                {tokenContent}
            </span>
        );
    };

    return (
        <div className="text-container" onMouseUp={handleMouseUp}>
            {tokens.map((token, index) => renderToken(token, index))}
        </div>
    );
};

export default TextAnnotator;
