import React, { useEffect } from "react";
import { 
  Typography,
  Chip, 
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

// import { AMR_TAGS } from '../store/actions';
/*
Stateful example with blended tags not allowed
*/
const findLongestValue = (nodeLs) => {
  let longest = "";

  for (const value of nodeLs) {
    if (value.surface.length > longest.length) {
      longest = value.surface;
    }
  }

  return longest;
};

const varPattern = /s(\d+)\w(\d+)/; 

function DocViewTextPanel({ text, tokens, setTokens, selectedCluster, allClusters, setAllClusters, uploadAMRCorefs, scrollToAMR}) {
  
  const handleTokenClick = (e, sentId, tokenVar, tokenSurface) => {
    console.log('in handleTokenClick');
    if (selectedCluster >= 0){
      // update local look 
      // first locate the token in the tokens list to see which sentence it is from
      const newTokens = tokens.map((perSentTokens, sId) => 
        sId === sentId 
          ? perSentTokens.map((tokenDict, iIndex) =>
              tokenDict.var === tokenVar 
                ? { ...tokenDict, bgc: (tokenDict.bgc === 'white'?'#007bff':'white')} 
                : tokenDict
            )
          : perSentTokens
      );
      setTokens(newTokens);

      // update the cluster 
      console.log('in DocViewTextPanel, allClusters:', allClusters);
      const newNodes = [...allClusters[selectedCluster]['nodes']];
      // Check if the item exists in the array
      const index = newNodes.findIndex(
        (nodeDict) =>
          nodeDict.var === tokenVar && nodeDict.surface === tokenSurface
      );

      // If the item exists, remove it; otherwise, add it
      if (index !== -1) {
        newNodes.splice(index, 1);
      } else {
        newNodes.push({var: tokenVar, surface: tokenSurface});
      }

      const longestSurface = findLongestValue(newNodes);
      console.log('newNodes now: ', newNodes);
      const newClusters = {...allClusters, [selectedCluster]: {...allClusters[selectedCluster], 'nodes': newNodes, 'name': longestSurface}};
      setAllClusters(newClusters);

      uploadAMRCorefs(newClusters);
    }
  };
  
  const renderTextWithChips = (sentID, text, perSentTokens) => {

    perSentTokens.sort((a, b) => a.start - b.start);
    const elements = [];
    let lastIndex = 0;

    perSentTokens.forEach((token, index) => {
      // const { start, end, var } = token;

      if (token.start > lastIndex) {
        elements.push(
          <span
            key={`s${sentID}-t${index}`}
            style={{ whiteSpace: 'pre-wrap' }}
          >
            {text.substring(lastIndex, token.start)}
          </span>
        );
      }
      elements.push(
        <Chip
          key={`${sentID}-${token.var}-${token.start}-${token.end}`}
          label={text.substring(token.start, token.end)}
          variant="outlined"
          size="small"
          sx={{
            margin: '0',
            display: 'inline-flex',
            verticalAlign: 'middle',
            fontSize: '1rem',
            border: '1px solid #000000',
            '& .MuiChip-label': {
              paddingLeft: '1px',
              paddingRight: '1px'
            },
            backgroundColor: token.bgc === 'white'? 'white': '#007bff',
            color: token.bgc === 'white'? 'black': 'white',
             '&&:hover': {
              backgroundColor: 'orange'
            },
          }}
          onClick={(e) => handleTokenClick(e, sentID, token.var, text.substring(token.start, token.end))}
        />
      );
      lastIndex = token.end;
    });

    if (lastIndex < text.length) {
      elements.push(
        <span
          key={`${sentID}-${lastIndex}`}
          style={{ whiteSpace: 'pre-wrap' }}
        >
          {text.substring(lastIndex)}
        </span>
      );
    }

    return elements;
  };


  return (

    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableBody>
          {text.map((sent, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
            >
              <TableCell component="th" scope="row" sx={{ fontSize: '1rem'}} onClick={() => scrollToAMR(index)}>
                {index+1}
              </TableCell>
              <TableCell align="left" sx={{ fontSize: '1rem'}}>{renderTextWithChips(index, sent, tokens[index] || [])}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default DocViewTextPanel;