export const preprocessCode = (code) => {
  const lines = code.split('\n');
  // Calculate the common indentation (minimum leading spaces) to remove
  const minIndentation = lines.reduce((minIndent, line) => {
    if (line.trim() !== '') {
      const lineIndent = line.match(/^\s*/)[0].length;
      return Math.min(minIndent, lineIndent);
    }
    return minIndent;
  }, Infinity);
  // Remove the common indentation from each line
  const processedCode = lines.map((line) => line.slice(minIndentation)).join('\n');
  return processedCode;
};

const adjustTokenTypes = (tokens) => {
  const adjustedTokens = tokens.map((tokenDict, index, allTokens) => {
    if (tokenDict.type === 'literal') {
      let prev_idx = index - 1;
      while (prev_idx >= 0 && allTokens[prev_idx].type === 'text') {prev_idx = prev_idx - 1}
      if (allTokens[prev_idx].type === 'punctuation' || allTokens[prev_idx].type === 'symbol'){
        return {...tokenDict, type: 'variable'};
      } else {
        return tokenDict;
      }
    } else {
      return tokenDict;
    }
  });
  return adjustedTokens;
}

export const tokenizePenman = (input) => {
  const rules = [
    { type: 'punctuation', regex: /[\(\)]/ },
    { type: 'variable', regex: /^s\d+\w\d*/ },
    { type: 'symbol', regex: /:[a-zA-Z][\w-]*/ },
    { type: 'string', regex: /"([^"]*)"/ },
    { type: 'comment', regex: /#.*$/ },
    { type: 'literal', regex: /\b[a-z][a-zA-Z0-9-]*\b/ }
  ];

  const tokens = [];
  let remainingText = input;

  while (remainingText.length > 0) {
    let matchFound = false;

    for (const rule of rules) {
      const match = remainingText.match(rule.regex);
      if (match && match.index === 0) {
        tokens.push({ type: rule.type, value: match[0] });
        remainingText = remainingText.slice(match[0].length);
        matchFound = true;
        break;
      }
    }

    if (!matchFound) {
      tokens.push({ type: 'text', value: remainingText[0] });
      remainingText = remainingText.slice(1);
    }
  }

  const adjustedTokens = adjustTokenTypes(tokens);
  return adjustedTokens;
};

export const sentenceLabelVars = (sentAMRTokens) => {
  const sentLabeledAMRTokens = sentAMRTokens.map((amrTokens, index) => amrTokens.map(amrTokenDict => (amrTokenDict.type === 'variable' ? {...amrTokenDict, value: `s${index+1}${amrTokenDict.value}`} : amrTokenDict)))
  return sentLabeledAMRTokens;
};