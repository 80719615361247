import React from 'react';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Popover from 'react-bootstrap/Popover';
import Button from 'react-bootstrap/Button';
import Divider from './Divider';


import NewWindow from 'react-new-window';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { red, deepOrange, amber, lightGreen, cyan, blue, purple } from '@mui/material/colors';
// import { CodeBlock, dracula, monokai } from "react-code-blocks";
import hljs from 'highlight.js/lib/core';
// import hljs from 'highlight.js';
import 'highlight.js/styles/docco.css';
// import penmanLanguage from './penmanLanguage';
import PenmanHighlight from './PenmanHighlight';
import { preprocessCode, tokenizePenman, sentenceLabelVars } from './penmanLanguage';

const colors = [red[500], deepOrange[500], amber[400], lightGreen[400], cyan[400], blue[400], purple[300]];
// SyntaxHighlighter.registerLanguage('penman', penmanLanguage);
// hljs.registerLanguage('penman', penmanLanguage);


const ReleaseListNWPop = ({sentAMRs=[], unloadFunc}) => {
  return (
    <>
    <NewWindow
      title="LDC Release Lookup"
      onUnload={unloadFunc}
      fetures={{left: 200, top: 200, width: 400, height: 400}}      
    >
    { sentAMRs.map((sntAMR,index) => {
        if (sntAMR) {
          var color_index = index % colors.length;
          var class_choice = colors[color_index];
          const divider = index > 0 ? (<span></span>) : null;
          
          const amrStr = preprocessCode(sntAMR.amr);

          const amrTokens = tokenizePenman(amrStr);

          return (
          <>
          {divider}
          <Card sx={{minWidth: 390, padding: '10px', margin: '2px'}} variant="outlined">
            <CardContent>
              
              <p className="line-p"><span className="example-name-text" style={{color: class_choice}}>sentence:</span>{sntAMR.snt}</p>
              <Divider spacing={1}>AMR</Divider>

              <PenmanHighlight 
                sntAMRtokens={[amrTokens]}
                setSentAMRS={null}
                codeOnly={true}
                sentID={0}
                allClusters={null}
                setAllClusters={null}
                selectedCluster={null}
                alignedTokens={null}
                setAlignedTokens={null}
              />
         
            </CardContent>
          </Card>
          </>
          )
         }
         return null
    }) }
    </NewWindow>
    </>
  );
}

export default ReleaseListNWPop