import React from 'react';
import NewWindow from 'react-new-window';

const PopupWindow = ({ url, onUnload }) => {
  return (
    <NewWindow url={url} onUnload={onUnload}>
      <div>Loading...</div>
    </NewWindow>
  );
};

export default PopupWindow;