import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardActions, Container, Chip, Typography, IconButton, Fab, Stack, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';

import "./AMRMapper.css";

const DividerIcon = () => (
  <span style={{ height: '24px', borderLeft: '3px solid grey', marginLeft: '8px', marginRight: '8px' }}></span>
);
const fabStyle = {
  position: 'absolute',
  bottom: 70,
  right: 16,
};

const fab = {
      color: 'primary',
      sx: fabStyle,
      icon: <AddIcon />,
      label: 'Add',
};

const options = {
  shouldForwardProp: (prop) => prop !== 'hoverShadow',
};


const StyledCard = styled(Card, { name: 'StyledCard' })(({ theme, hoverShadow = 1, selected }) => ({
  ':hover': {
    boxShadow: theme.shadows[hoverShadow],
  },
  backgroundColor: selected ? theme.palette.primary.main : theme.palette.background.paper,
  color: selected ? theme.palette.primary.contrastText : theme.palette.text.primary,
}));

const AMRMapper = ({ onCreateNewCluster, onDeleteCluter, allClusters, currentCluster, setCurrentCluster }) => {  
  const [selectedChips, setSelectedChips] = useState([]);


  useEffect(() => {
    console.log('Selected chips updated:', selectedChips);
  }, [selectedChips]);

  // useEffect(() => {
  //   getMsDocPage().then(res => {
  //     console.log('MsDocPage:', res.data);
  //   });
  // }, []);

  const handleChipClick = (chipId) => {
    console.log(chipId, 'has been selected');
  };

  const handleClusterClick = (clusterID) => {
    console.log(clusterID, 'has been clicked');
    
    setCurrentCluster((prevIndex) => (prevIndex === clusterID ? null : clusterID));
  };

  const isSelected = (chipId) => selectedChips.includes(chipId);

  const handleButtonClick = (event, clusterID) => {
    event.stopPropagation();
    onDeleteCluter(clusterID);
  };

  return (    
    <Stack m={3} gap={3}>
      {Object.entries(allClusters).map(([clusterID, cluster]) => (
        <StyledCard 
          hoverShadow={10} 
          id={`cluster-{clusterID}`}
          key={clusterID}
          selected={currentCluster === clusterID}
          onClick={(e) => handleClusterClick(clusterID)}
        >
          <CardContent>
            {cluster.nodes.map((nodeDict) => (
              <Chip 
                key={`${nodeDict.var}-${nodeDict.surface}`}
                className={"m-1 document-chip"} 
                onClick={() => handleChipClick(nodeDict.var)}
                sx={{
                  border: '1px solid #000000',
                  backgroundColor: 'white', // Non-transparent filled background color
                  color: 'black',                  // Text color
                }}
                label={<>{nodeDict.var}<DividerIcon/>{nodeDict.surface}</>}
              />
            ))}
          </CardContent>
          <CardActions>
            <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
              <Button size="small" id={cluster.id} onClick={(e) => handleButtonClick(e, clusterID)} color="error" variant="contained">Remove</Button>
              <Typography variant="h6" color="text.secondary">
                {cluster.name}
              </Typography>
            </Stack>
          </CardActions>
        </StyledCard>
      ))}
      <Fab 
        sx={fab.sx}
        aria-label={fab.label}
        color={fab.color}
        onClick={(e) => onCreateNewCluster()}
      >
        {fab.icon}
      </Fab>
    </Stack>
  );
};

export default AMRMapper;