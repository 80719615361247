// const amr_var_regex = /\(\s*(\w\d*)\s*\//ig;
// const areSetsEqual = (a, b) => a.size === b.size && [...a].every(value => b.has(value));

// export const checkNewVar = (amr_string, existing_amr_vars) => {
//   const cur_amr_vars_ls = [...amr_string.matchAll(amr_var_regex)];
//   const cur_amr_vars = cur_amr_vars_ls.map((x) => x[1]);
//   const cur_amr_vars_set = new Set(cur_amr_vars);
//   const existing_amr_vars_set = new Set(existing_amr_vars);
//   if (areSetsEqual(cur_amr_vars_set, existing_amr_vars_set)) {
//     return [true, []];
//   } 
//   return [false, Array.from(cur_amr_vars_set)];
// };

const amr_var_concept_regex = /\(\s*(\w\d*)\s*\/\s*([^\s\)]+)/ig;
const amr_var_temp_regex = /\(\s*(var\d*)\s*\/\s*([^\s\)]+)/ig;
const areSetsEqual = (a, b) => a.size === b.size && [...a].every(value => b.has(value));

export const checkNewVar = (amr_string, existing_amr_vars) => {
  const cur_amr_vars_ls = [...amr_string.matchAll(amr_var_concept_regex)];
  const cur_amr_vars = cur_amr_vars_ls.map((x) => x[1]);
  const cur_amr_vars_set = new Set(cur_amr_vars);
  const existing_amr_vars_set = new Set(existing_amr_vars);

  // Create a dictionary to map variables to their concepts
  const var_to_concept = {};
  cur_amr_vars_ls.forEach((x) => {
    var_to_concept[x[1]] = x[2];
  });

  if (areSetsEqual(cur_amr_vars_set, existing_amr_vars_set)) {
    return [true, [], var_to_concept];
  } 
  return [false, Array.from(cur_amr_vars_set), var_to_concept];
};

// Function to generate a unique variable name
const generateUniqueVar = (concept_initial, existing_vars) => {
  if (!existing_vars.has(concept_initial)) {
    return concept_initial;
  }
  let i = 1;
  let new_var;
  do {
    new_var = `${concept_initial}${i}`;
    i++;
  } while (existing_vars.has(new_var));
  return new_var;
};

export const updateVars = (amr_string) => {
  const amr_vars_ls = [...amr_string.matchAll(amr_var_concept_regex)];
  const amr_temp_vars_ls = [...amr_string.matchAll(amr_var_temp_regex)];
  // Create a dictionary to map variables to their concepts
  const var_to_concept = {};
  amr_temp_vars_ls.forEach((x) => {
    var_to_concept[x[1]] = x[2];
  });


  // Set to store existing variable names
  const existing_vars = new Set(amr_vars_ls.map(x => x[1]));

  // Replace temp variables with permanent ones
  Object.keys(var_to_concept).forEach(temp_var => {
    const concept_initial = var_to_concept[temp_var][0].toLowerCase();
    const new_var = generateUniqueVar(concept_initial, existing_vars);
    
    // Replace all occurrences of the temporary variable in the string
    const temp_var_regex = new RegExp(`\\b${temp_var}\\b`, 'g');
    amr_string = amr_string.replace(temp_var_regex, new_var);
    
    // Add the new variable to the set of existing variables
    existing_vars.add(new_var);
  });

  return amr_string;

};