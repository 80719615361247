import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Snackbar from '@mui/material/Snackbar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// import LockOutlinedIcon from '@mui/material/LockOutlined';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LOGIN, UPDATE_WSET } from '../store/actions';
import { AcctPassLogin, getProfileData } from '../services/auth';

const CULinkStyle = {
    display: 'inline-block',
    color: '#cfb87c',
    fontWeight: '400',
    textDecoration: 'none',
    transition: 'background-color 0.25s ease, border-color 0.25s ease, color 0.25s ease',
        '&:hover': {
          color: '#fff', // Add your desired hover color here
          textDecoration: 'none'
        },
};

const getRandomImage = () => {
  const index = Math.floor(Math.random() * 12) + 1; // Random index from 1 to 12
  return `/bgis/bgi${index}.jpeg`; // Adjust the file extension if necessary
};

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://clear.colorado.com/">
        CAMRA
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignInSide() {
  // const backgroundImage = getRandomImage();
  // console.log('backgroundImage: ', backgroundImage);
  const [failLoginOpen, setFailLoginOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState(null);
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);
  // const [checked, setChecked] = useState(true);
  const navigate = useNavigate();
  // background image generation ----------
  const [backgroundImage, setBackgroundImage] = useState('');

  useEffect(() => {
    // Set background image only when the component mounts
    setBackgroundImage(getRandomImage());
  }, []); // Empty dependency array ensures this runs only on mount
  // background image generation ----------
  const { isLoggedIn } = account;
  // open tooltip ------------------------------
  const [forgotPOpen, setforgotPOpen] = useState(false);

  const handleForgotPTooltipClose = () => {
    setforgotPOpen(false);
  };

  const handleForgotPTooltipOpen = () => {
    setforgotPOpen(true);
  };
  const [registerOpen, setregisterOpen] = useState(false);

  const handleRegisterTooltipClose = () => {
    setregisterOpen(false);
  };

  const handleRegisterTooltipOpen = () => {
    setregisterOpen(true);
  };
  // end open tooltip --------------------------
  const handleFailLoginClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setFailLoginOpen(false);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // console.log({
    //   email: data.get('email'),
    //   password: data.get('password'),
    // });
    console.log('in onSubmit, ', data);
    setSubmitting(true);

    const response = await AcctPassLogin(data.get('email'), data.get('password')).catch((e) => {
      console.error(e);    
      
      setErrors(e.message);
      setSubmitting(false);
      setFailLoginOpen(true);

    });

    if (response !== undefined) {

      // retrieve profile ino

      dispatch({
        type: LOGIN,
        payload: { userDict: response.data.user, accessTK: response.data.access, refreshTK: response.data.refresh }
      });
      const userProfile = await getProfileData();
      const lastWorksetID = userProfile['last_workset_id']

      dispatch({
        type: UPDATE_WSET,
        payload: { newWsetID: lastWorksetID }
      });
      navigate('/', { replace: true });



      // setUser(resp.data);
      console.log('navigation pushing now');
      setSubmitting(false);
    }
  };

  // -------password--------
  // const [checked, setChecked] = useState(true);

  // -------password--------

  useEffect(() => {
    if (isLoggedIn) {
      console.log('actually triggered navi ');
      navigate('/', { replace: true });
    }
  }, [isLoggedIn, navigate]);

  const handleUserInit = useCallback(
    (resp) => {
      if (resp.ok) {
        dispatch({
          type: LOGIN,
          payload: { userDict: resp.data.user, access: resp.data.access, refresh: resp.data.refresh }
        });
        console.log('resp.data: ', resp.data);
        console.log('navigation pushing now');
        navigate('/', { replace: true });
        // setUser(resp.data);
      } else {
        // notifyError(resp.data[0]);
        alert(resp.data[0]);
      }
    },
    [dispatch]
  );

  return (
    <ThemeProvider theme={defaultTheme}>

      <Snackbar
        open={failLoginOpen}
        autoHideDuration={6000}
        onClose={handleFailLoginClose}
        message="Wrong Email-Password Combo."
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      />

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={submitting}
        // onClick={handleLoadingClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box component="img" 
          src="https://cdn.colorado.edu/static/brand-assets/live/images/cu-boulder-logo-text-black.svg"
          alt="University of Colorado Boulder"
          sx={{
            maxWidth: '240px',
            height: 'auto',
            backgroundColor: '#fff',
            display: 'flex',
            flexDirection: 'row',
            marginTop: '20px',
            marginLeft: '20px',
          }}/>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: '#cfb87c' }}>
              <VpnKeyOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%', // Ensures the buttons span across the row
                }}
              >
                <ClickAwayListener onClickAway={handleForgotPTooltipClose}>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleForgotPTooltipClose}
                    open={forgotPOpen}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                      <React.Fragment>
                        <Typography variant="body1" component="p">
                          Please email:
                        </Typography>
                        <Typography variant="body2" component="p" sx={{ fontWeight: 'bold' }}>
                          jon.z.cai@colorado.edu
                        </Typography>
                        <Typography variant="body2" component="p">
                          Make sure to email from your registered email address.
                        </Typography>
                      </React.Fragment>
                    }
                  >
                    <Button sx={{textTransform: 'none'}} onClick={handleForgotPTooltipOpen}>Forgot Password?</Button>
                  </Tooltip>
                </ClickAwayListener>
                <ClickAwayListener onClickAway={handleRegisterTooltipClose}>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleRegisterTooltipClose}
                    open={registerOpen}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                      <React.Fragment>
                        <Typography variant="body1" component="p">
                          Please email:
                        </Typography>
                        <Typography variant="body2" component="p" sx={{ fontWeight: 'bold' }}>
                          jon.z.cai@colorado.edu
                        </Typography>
                        <Typography variant="body2" component="p">
                          Make sure to include your intented handle email and use cases of CAMRA.
                        </Typography>
                      </React.Fragment>
                    }
                  >
                    <Button sx={{textTransform: 'none'}} onClick={handleRegisterTooltipOpen}>Don't Have an Account?</Button>
                  </Tooltip>
                </ClickAwayListener>
              </Box>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
          <Box
            component='footer'
            sx={{
              position: 'fixed',
              bottom: 0,
              width: '100%',
              marginTop: "auto",
              paddingLeft: '20px',
              paddingTop: '10px',
              paddingBottom: '10px',
              backgroundColor: '#212529',
              fontFamily: "'Roboto', sans-serif",
              fontFamily: "'Roboto Condensed', sans-serif",
          }}>
            
                <Box component="img" 
                  src="https://cdn.colorado.edu/static/brand-assets/live/images/be-boulder-white.svg"
                  alt="Be Boulder"
                  alignItems='left'
                  sx={{
                    maxWidth: '240px',
                    width: '100%',
                    height: 'auto',
                    display: 'inline-block',
                    marginTop: '10px',
                }}/>
                <Link sx={{
                  display: 'flex',
                  color: '#cfb87c',
                  textDecoration: 'none',
                  fontWeight: 'bold',
                  transition: 'background-color 0.25s ease, border-color 0.25s ease, color 0.25s ease',
                      '&:hover': {
                        color: '#fff', // Add your desired hover color here
                        textDecoration: 'none'
                      },                  
                }}
                alignItems='left'
                href="https://www.colorado.edu"
                >
                  University of Colorado Boulder
                </Link>
                <Typography variant="body2" color="white" alignItems='left'>
                  © Regents of the University of Colorado
                </Typography>
                <Typography variant="body2" alignItems='left'>
                <Link sx={CULinkStyle}
                  alignItems='left'
                  href="https://www.colorado.edu/about/privacy-statement"
                  >
                  Privacy
                </Link>
                 <span style={{color: 'white', display: 'inline-block', whiteSpace: 'pre'}}> &bull; </span> 
                <Link sx={CULinkStyle}
                  alignItems='left'
                  href="http://www.colorado.edu/about/legal-trademarks"
                  >
                  Legal & Trademarks
                </Link>
                 <span style={{color: 'white', display: 'inline-block', whiteSpace: 'pre'}}> • </span> 
                <Link sx={CULinkStyle}
                  alignItems='left'
                  href="http://www.colorado.edu/map"
                  >
                  Campus Map
                </Link>
                </Typography>

          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}