import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// project imports
import React, { useEffect, useState } from 'react';

// ==============================|| AUTH GUARD ||============================== //


const AuthGuard = ({ children }) => {
    const account = useSelector((state) => state.account);
    const { isLoggedIn } = account;
    const navigate = useNavigate();
    useEffect(() => {
      console.log('AuthGuard useEffect')
        if (!isLoggedIn) {
            console.log('not login in yet');
            navigate('/login', { replace: true });
        }
    }, [isLoggedIn]);

    return <React.Fragment>{children}</React.Fragment>;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;

// import React, { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// // import AuthService from '../services/auth';

// const AuthGuard = ({ component }) => {
//     const [status, setStatus] = useState(false);
//     const navigate = useNavigate();


//     useEffect(() => {
//         checkToken();
//     }, [component]);

//     const checkToken = async () => {
//         try {
//             const user = useSelector((state) => state.account);
//             const { isLoggedIn } = user;
//             if (!isLoggedIn) {
//                 navigate(`/Login`)
//             } else {
//                 navigate(`/`);
//             }
//             setStatus(true);
//         } catch (error) {
//             navigate(`/Login`);
//         }
//     }

//     return status ? <React.Fragment>{component}</React.Fragment> : <React.Fragment></React.Fragment>;
// }

// export default AuthGuard;