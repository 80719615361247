
import React, { useState, useEffect } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import MUIButton from '@mui/material/Button';
// import Stack from '@mui/material/Stack';

import Button from '@mui/joy/Button';
import SvgIcon from '@mui/joy/SvgIcon';
import { styled } from '@mui/joy';

// ----------------table------------------
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
// import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
// --------------end table----------------
import { getWorkSet, postWorkSet, delWorkSet, getAMRDataFromWorkSet, postProfileData, getProfileData } from '../services/auth';
import { LOGOUT, UPDATE_WSET } from '../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// --------------START table----------------
// --------------loading UI-----------------
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
// -----------------------------------------

function createData(id, setname, sentNum, lastIndex, averageLens, active) {
  return {
    id,
    setname,
    sentNum,
    lastIndex,
    averageLens,
    active
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


const headCells = [
  {
    id: 'setname',
    numeric: false,
    disablePadding: true,
    label: 'Workset',
  },
  {
    id: 'sentNum',
    numeric: true,
    disablePadding: false,
    label: 'Sent Number',
  },
  {
    id: 'lastIndex',
    numeric: true,
    disablePadding: false,
    label: 'Last Edit Index',
  },
  {
    id: 'averageLens',
    numeric: true,
    disablePadding: false,
    label: 'Average Sent. Len.',
  },
  {
    id: 'active',
    numeric: false,
    disablePadding: false,
    label: 'Active',
  },
  {
    id: 'download',
    numeric: false,
    disablePadding: false,
    label: 'Download',
  },    
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            // align='center'
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, handleDeleteClick } = props;
  
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Worksets
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton onClick={handleDeleteClick}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
};

// --------------END table----------------

function organize_data_dict_from_txt(text_str){
  const sents = text_str.split(/\r?\n/);
  const json_data_dict = sents.map((sent) => {
    return {'text': sent, 'AMR': '', 'AMR_vars': [], 'AMR_aligns': [], 'parser_result': ''};
  });
  return json_data_dict;
}

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

let rowsInitial = [];

function WorksetManager({show, onHide, changeCurrentWorkset}) {

  const dispatch = useDispatch();
  // const [checked, setChecked] = useState(true);
  const navigate = useNavigate();  
  const [rows, setRows] = useState(rowsInitial);
  const [currentWorkSet, setCurrentWorkSet] = useState({});
  const [allWorkSets, setAllWorkSets] = useState([]);  
  const [loading, setLoading] = useState(false);

  useEffect(() => {
     (async () => {
        setLoading(true);
        try {
          const userProfile = await getProfileData();
          const lastWorksetID = userProfile['last_workset_id']
          // prepare text:
          const fetchedWorksets = await getWorkSet()
          console.log("fetchedWorksets: ", fetchedWorksets);

      
          setAllWorkSets(fetchedWorksets);
          const current_workset = fetchedWorksets.find(item => item.id === lastWorksetID);

          setCurrentWorkSet(current_workset);
          const newRows = update_rows(fetchedWorksets, lastWorksetID)
          setRows(newRows);

        } catch (error) {
          console.error('Error fetching data:', error);
          dispatch({ type: LOGOUT });
          navigate('/login', { replace: true });

        } finally {
          setLoading(false);
        }
      })();
    }, []
  );

  const update_rows = (fileNamesLs, currentWorkSetID) => {
    const newrows = fileNamesLs.map((filenameDict) => {

      const numSents = filenameDict['num_sents']?filenameDict['num_sents']:0;
      const lastIdx = filenameDict['last_index']?filenameDict['last_index']:0;
      const atps = filenameDict['average_tks_per_sent']?filenameDict['average_tks_per_sent']:0.0;

      const active = (filenameDict['id'] === currentWorkSetID);
      return createData(filenameDict['id'], filenameDict['set_name'], numSents, lastIdx, atps, active);
    });   
    return newrows;
  };

  const handleFileUpload = (event) => {
    // setAllWorkSets(acceptedFiles.map(file => file.name));
    setLoading(true);
    const acceptedFile = event.target.files[0];
    let fileReader = new FileReader();

    fileReader.readAsText(acceptedFile);
    fileReader.fileName = acceptedFile.name;
    fileReader.onload = async (e) => {
      // files[e.target.fileName]=e.target.result;
      let localFileName = e.target.fileName;
      if (allWorkSets.includes(localFileName)){
        alert("existing file");
      } else if (localFileName.endsWith(".txt")){

        // var data_dict_str = JSON.stringify(data_dict);
        // upload new workset
        const new_workset = organize_data_dict_from_txt(e.target.result);
        const resp = await postWorkSet({fileName: localFileName, data: new_workset}).catch((e) => {
          console.log(e);
        });
        console.log('handleFileUpload resp: ', resp);
        if (resp && resp.hasOwnProperty('id')) {
          // change states
          const updated_worksets = [...allWorkSets, resp];
          setAllWorkSets(updated_worksets);
          setRows(update_rows(updated_worksets, currentWorkSet['id']));
        }

      } else if (localFileName.endsWith(".json")){
        try{
          const new_workset = JSON.parse(e.target.result);
          const resp = await postWorkSet({fileName: localFileName, data: new_workset});
          if (resp && resp.hasOwnProperty('id')) {
            // change states
            const updated_worksets = [...allWorkSets, resp];
            setAllWorkSets(updated_worksets);
            setRows(update_rows(updated_worksets, currentWorkSet['id']));
          }
        } catch (error) {
          console.log('Error uploading workset: ', error);
          const errorMessage = error.response && error.response.data && error.response.data.error 
            ? error.response.data.error 
            : error.message;
          alert(`Error uploading workset: ${errorMessage}`);
        } 
      } else {
        alert("Only support TXT or JSON format ‎(·•᷄ࡇ•᷅ ）Sorry");
      }
      setLoading(false);
    }
  };
  // START download workset function --------------
  const handleDownloadWorkset = async (e, workset_id) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Fetch data from backend
      const fetchedWorksets = await getWorkSet();
      const target_workset = fetchedWorksets.find(workset_dict => {
        return workset_dict.id === workset_id
      });
      console.log('target_workset: ', target_workset);
      const response = await getAMRDataFromWorkSet(workset_id);  // Adjust this endpoint to match your backend route
      const data = response;
      console.log('data:', response);
      // Create a blob from the JSON data
      const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
      const url = window.URL.createObjectURL(blob);

      // Create a link element and trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${target_workset.set_name}.json`);  // Name of the downloaded file
      document.body.appendChild(link);
      link.click();

      // Clean up
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading workset:', error);
    }
    setLoading(false);
  }; 
  // END download workset function ---------------- 
  // start table states ------------------------------
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);

  const [page, setPage] = React.useState(0);
  // const [dense, setDense] = React.useState(false);
  const dense = false;
  const [rowsPerPage, setRowsPerPage] = React.useState(5);  

   const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  }; 

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleActiveChange = async (id) => {
    // 1. set currentWorkSet 
    const targetFilenameDict = allWorkSets.find((filenameDict) => filenameDict.id === id);
    setCurrentWorkSet(targetFilenameDict);
    // 2. update rows
    const newRows = rows.map((row) => {
      if (row.id === id) {
        return {...row, active: true}
      } else {
        return {...row, active: false}
      }
    });
    setRows(newRows);
    // 3. update editor content 
    setLoading(true);
    getAMRDataFromWorkSet(id).then(resp => {
      console.log("targetFilenameDict['last_index']: ", targetFilenameDict['last_index']);
      const lastIdx = targetFilenameDict['last_index'];
      console.log('Uploader, handleActiveChange, getAMRDataFromWorkSet resp: ', resp);
      changeCurrentWorkset(resp.amrs, resp.last_index);
    }).catch(e => {console.log(e);})
    setLoading(false);
    // 4. update 
    const response = await postProfileData({'last_workset': id});
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleDeleteWorkset = async () => {
    const resp = await delWorkSet({selectedWorksetIDs: selected}).catch((e) => {
      console.log(e);
    });
    console.log('handleDeleteWorkset response: ', resp);
    if (resp && resp.message === 'accepted'){
      // update the rows and allWorkSets
      const newRows = rows.filter((row) => !selected.some((s) => s === row.id));
      console.log('newRows: ', newRows);
      setRows(newRows);
      const newAllWorkSets = allWorkSets.filter((workset) => !selected.some((s) => s === workset.id));
      console.log('newAllWorkSets: ', newAllWorkSets);
      setAllWorkSets(newAllWorkSets);
      // reset selected
      setSelected([]);
    } else {
      console.log(resp);
    }
  };
  // end table states ------------------------------

  return (
    <Dialog 
      open={show}
      onClose={onHide}
      maxWidth='lg'
      fullWidth={true}
      style={{zIndex: 11}}
    > 
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        // onClick={handleLoadingClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>   
      <DialogTitle style={{ textAlign: 'center', margin: 'auto' }}>Workset Management</DialogTitle>
      <DialogContent style={{zIndex: 11}}>
        <h6>Your WorkSets</h6>
        <Box sx={{ width: '100%'}} style={{zIndex: 11}}>
          <Paper sx={{ width: '100%', mb: 2 }} style={{zIndex: 11}}>
            <EnhancedTableToolbar numSelected={selected.length} handleDeleteClick={handleDeleteWorkset}/>
            <TableContainer style={{zIndex: 11}}>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover

                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                        sx={{ cursor: 'pointer' }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                            onClick={(event) => handleClick(event, row.id)}
                            disabled={row.setname === 'MOCK_DATA'}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.setname}
                        </TableCell>
                      
                        <TableCell align="right">{row.sentNum}</TableCell>
                        <TableCell align="right">{row.lastIndex}</TableCell>
                        <TableCell align="right">{row.averageLens}</TableCell>
                        <TableCell>
                          <Switch
                            checked={row.active}
                            onChange={(e) => handleActiveChange(row.id)}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton color="secondary" aria-label="download" onClick={(e) => handleDownloadWorkset(e, row.id)}>
                             <SimCardDownloadOutlinedIcon />
                          </IconButton>
                        </TableCell>  
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              style={{zIndex: 12}}
            />
          </Paper>
        </Box>
        
        <h6>Upload a new Workset</h6>
        <div>
          <Button
            component="label"
            role={undefined}
            tabIndex={-1}
            variant="outlined"
            color="neutral"
            startDecorator={
              <SvgIcon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                  />
                </svg>
              </SvgIcon>
            }
          >
            Upload a file (txt or json)
            <VisuallyHiddenInput type="file" onChange={handleFileUpload}/>
          </Button>
        </div>
      </DialogContent>
      <DialogActions>
        {/*<Button variant="warning" onClick={handleFileSelection}>Open</Button>*/}
        <MUIButton variant="warning" onClick={onHide}>Done</MUIButton>
      </DialogActions>
    </Dialog>
  );
}


export default WorksetManager;