import React from 'react';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Popover from 'react-bootstrap/Popover';
import Button from 'react-bootstrap/Button';
import Divider from './Divider';
import NewWindow from 'react-new-window';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { red, deepOrange, amber, lightGreen, cyan, blue, purple } from '@mui/material/colors';


const colors = [red[500], deepOrange[500], amber[400], lightGreen[400], cyan[400], blue[400], purple[300]];

const PropbankRoleListPop = ({roleList=[], unloadFunc}) => {
  return (
    <>
    <NewWindow
      title="Propbank Lookup"
      onUnload={unloadFunc}
      fetures={{left: 200, top: 200, width: 400, height: 400}}      
    >
    { roleList.map((roleset,index) => {
        if (roleset) {
          var color_index = index % colors.length;
          var class_choice = colors[color_index];
          
          return (
                  <>
                  <Card sx={{minWidth: 390, padding: '10px', margin: '2px'}} variant="outlined">
                    <CardContent>
                      <Typography variant="h5" component="div" fontWeight="fontWeightBold" style={{color: colors[color_index]}}>
                        {roleset.roleset_id}
                      </Typography>
                      <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        Description: {roleset.roleset_name}
                      </Typography>
                      {roleset.roles.map((role_dict) => {if (role_dict.f !== null) {return (
                          <Typography>
                            <Box display="inline" fontWeight="fontWeightBold">{role_dict.f}-{role_dict.n}</Box>: {role_dict.descr}
                          </Typography>
                        )}
                      else {return null}
                        })
                      }
                      {roleset.examples.map((example, indexe) => {
                          if (example){
                            
                            const divider = (<Divider spacing={1}>example {indexe+1}</Divider>);

                            return (
                              <div className="example-block">
                                {divider}
                                <p className="line-p"><span className="example-name-text">{example.example_name}:</span> {example.example_text}</p>
                                <p className="line-p"><strong>REL:</strong> {example.rel.rel_text}</p>
                                {example.args.map((arg, indexa) => {
                                    if (arg) {
                                      return (<p className="line-p"><strong>Arg{arg.n}-{arg.f}: </strong>{arg.arg_text}</p>)
                                    }
                                    return null
                                  }
                                )}
                              </div>

                            )
                          }
                          return null
                        }
                      )}
                    </CardContent>
                  </Card>
                  </>
                 ) 
         }
         return null
    }) }
    </NewWindow>
    </>
  );
}

export default PropbankRoleListPop